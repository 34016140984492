import styled, {css} from "styled-components";

export const Container = styled.a<{ $is_footer: boolean }>`
    ${({theme: {colors, unit, breakpoints}, $is_footer}) => css`
        padding: ${unit * 2}px ${unit * 3}px;
        width: fit-content;
        height: fit-content;
        background-color: ${colors.white};
        display: flex;
        flex-direction: column;
        gap: ${unit}px;
        align-items: center;

        ${!$is_footer && css`
            align-items: start;
            padding: ${unit * 2}px ${unit * 8}px;
        `}
        svg {
            height: ${unit * 2}px !important;
            max-height: ${unit * 2}px !important;
            min-height: ${unit * 2}px !important;
            width: 154px !important;
        }

        @media (max-width: ${breakpoints.maxMobileWidth}) {
            padding: ${unit}px ${unit * 3}px;
            align-items: center;
            ${!$is_footer && css`
                padding: ${unit * 2.5}px ${unit * 3}px;
            `}
            
            svg {
                height: ${unit * 1.5}px !important;
                max-height: ${unit * 1.5}px !important;
                min-height: ${unit * 1.5}px !important;
                width: 116px !important;
            }
        }
    `}
`;

export const Text = styled.div<{ $is_footer: boolean }>`
    ${({theme: {colors, breakpoints}, $is_footer}) => css`
        line-height: 1;
        color: ${colors.black};
        font-size: 13px;
        word-break: break-word;
        text-align: center;
        font-family: 'Inter';

        ${!$is_footer && css`
            font-size: 15px;
            max-width: 120px;
            text-align: start;
        `};

        @media (max-width: ${breakpoints.maxMobileWidth}) {
            font-size: 11px;
            max-width: initial;
            text-align: center;
        }
    `}
`;

