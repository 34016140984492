import React, {FC, useState} from 'react';
import * as S from './styled';
import {CustomIcon} from '@components/ui';
import Search from "@components/entities/search";
import {NavItems} from "@components/entities/header/nav-items";
import {useTranslation} from "next-i18next";
import {useMedia} from "@core/hooks/use-media";
import {langEnum} from "@core/stores/core/types";
import {observer} from "mobx-react-lite";
import {useStore} from "@core/hooks/use-store";
import {useRouter} from "next/router";

interface IHeader {
	setIsShowSidebar(type: "collections" | "products" | "main"): void;
}

const Header: FC<IHeader> = ({setIsShowSidebar}) => {
	const router = useRouter();
	const {isDesktop} = useMedia();
	const {t, i18n} = useTranslation();
	const [searchOpen, setSearchOpen] = useState(false);
	const {isClient} = useStore("core")

	const sidebarOpenHandler = (path: "collections" | "products") => {
		setIsShowSidebar(path);
	}

	const changeLang = (lang: langEnum) => {
		const newLang = () => {
			switch (lang) {
				case langEnum.de:
					return "de"
				case langEnum.en:
					return "en"
				case langEnum.it:
					return "it"

			}
		}
		i18n.changeLanguage(newLang());
		router.replace(router.pathname, router.asPath, { locale: lang });
	}

	return (
		<S.HeaderContainer>
			<S.MenuWrapper>
				{isClient && isDesktop ? (
					<S.LinksList>
						{NavItems.map((nav) =>
							nav.is_link ? (
								<S.StyledLink
									key={nav.title}
									href={`/${nav.path}`}
								>
									{t(nav.title)}
								</S.StyledLink>
							) : (
								<S.StyledLinkButton
									key={nav.title}
									onClick={() => sidebarOpenHandler(nav.path as ("collections" | "products"))}
								>
									{t(nav.title)}
								</S.StyledLinkButton>
							),
						)}
					</S.LinksList>
				) : (
					<S.IconButton onClick={() => setIsShowSidebar('main')}>
						<CustomIcon fileName="icon-menu" />
					</S.IconButton>
				)}
			</S.MenuWrapper>

			<S.LogoWrapper href="/">
				<CustomIcon fileName="logo" />
			</S.LogoWrapper>

			<S.MenuWrapper>
				{isClient && isDesktop && (
					<S.LanguageList>
						<S.LanguageItem
							$isActive={i18n.language === langEnum.it}
							onClick={() => changeLang(langEnum.it)}
						>
							{t('lang.ita')}
						</S.LanguageItem>
						<S.LanguageItem
							$isActive={i18n.language === langEnum.en}
							onClick={() => changeLang(langEnum.en)}
						>
							{t('lang.eng')}
						</S.LanguageItem>
						<S.LanguageItem
							$isActive={i18n.language === langEnum.de}
							onClick={() => changeLang(langEnum.de)}
						>
							{t('lang.de')}
						</S.LanguageItem>
					</S.LanguageList>
				)}
				<S.ButtonsWrapper>
					<S.IconLink href="/favorites">
						<CustomIcon fileName="icon-favorites" />
					</S.IconLink>
					<S.IconButton onClick={() => setSearchOpen(true)}>
						<CustomIcon fileName="icon-search" />
					</S.IconButton>
				</S.ButtonsWrapper>
			</S.MenuWrapper>
			{searchOpen && <Search onClose={() => setSearchOpen(false)} />}
		</S.HeaderContainer>
	);
};

export default observer(Header);
