import React from "react";

type IconProps = {
    fileName: string;
};

const CustomIcon: React.FC<IconProps> = ({fileName}) => {

    const getIconPath = (fileName: string) => `/icons/${encodeURIComponent(fileName)}.svg#${encodeURIComponent(fileName)}`;

    return (
        <svg width="100%" height="100%">
            <use href={getIconPath(fileName)} width="100%" height="100%"/>
        </svg>
    );
};

export default CustomIcon;
