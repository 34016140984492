import React, {FC} from 'react';
import * as S from './styled';
import { CustomIcon } from '@components/ui';
import {useTranslation} from "next-i18next";
import {langEnum, sidebarType} from "@core/stores/core/types";
import {NavItems} from "@components/entities/header/nav-items";
import { useRouter } from 'next/router';
import DistributorLabel from "@components/ui/distributor-label";

interface IFooter {
	setIsShowSidebar(type: sidebarType): void
}

const Footer: FC<IFooter> = ({setIsShowSidebar}) => {

	const {t, i18n} = useTranslation();
	const router = useRouter()


	const changeLang = (lang: langEnum) => {
		i18n.changeLanguage(lang);
		router.replace(router.pathname, router.asPath, { locale: lang });
	}

	return (
		<S.FooterContainer>
			<CustomIcon fileName="logo" />
			<S.LinksList>
				{NavItems.map((nav, idx) =>
					nav.path === 'products' ? (
						<S.StyledButton
							key={idx}
							onClick={() => setIsShowSidebar('products')}
						>
							{t(nav.title)}
						</S.StyledButton>
					) : (
						<S.StyledLink
							href={`/${nav.path}`}
							key={idx}
						>
							{t(nav.title)}
						</S.StyledLink>
					),
				)}
			</S.LinksList>
			<S.LanguageList>
				<S.LanguageItem onClick={() => changeLang(langEnum.it)}>
					{t('lang.ita')}
				</S.LanguageItem>
				<S.LanguageItem onClick={() => changeLang(langEnum.en)}>
					{t('lang.eng')}
				</S.LanguageItem>
				<S.LanguageItem onClick={() => changeLang(langEnum.de)}>
					{t('lang.de')}
				</S.LanguageItem>
			</S.LanguageList>
			<S.Text>© Artiviana, 2024</S.Text>
			<DistributorLabel footer />
		</S.FooterContainer>
	);
};

export default Footer;
