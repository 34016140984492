import React, {FC} from "react";
import {CustomIcon} from "@components/ui";
import {useTranslation} from "next-i18next";
import * as S from "./styled";

const DistributorLabel: FC<{ footer: boolean }> = ({footer}) => {
    const {t} = useTranslation();

    return (
        <S.Container
            $is_footer={footer}
            href="https://www.reuter.de/katalogsuche/filter/hersteller/artiviana"
            target="_blank"
            rel="noreferrer"
        >
            <CustomIcon fileName="logo_reuter_de_neg"/>
            <S.Text $is_footer={footer}>{t('contacts.distributor')}</S.Text>
        </S.Container>
    )
}

export default DistributorLabel;