import * as styledComponents from 'styled-components';

const style = {
	default: styledComponents.default,
	css: styledComponents.css,
	ThemeProvider: styledComponents.ThemeProvider,
	ThemeContext: styledComponents.ThemeContext,
	createGlobalStyle: styledComponents.createGlobalStyle,
};

export const { css, createGlobalStyle, ThemeProvider, ThemeContext } = style;

export * from './theme';

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
		html {
			font-family: sans-serif; /* 1 */
			-ms-text-size-adjust: 100%; /* 2 */
			-webkit-text-size-adjust: 100%; /* 2 */
		}

		article,
		aside,
		details,
		figcaption,
		figure,
		footer,
		header,
		hgroup,
		main,
		menu,
		nav,
		section,
		summary {
			display: block;
		}

		audio,
		canvas,
		progress,
		video {
			display: inline-block; /* 1 */
			vertical-align: baseline; /* 2 */
		}

		audio:not([controls]) {
			display: none;
			height: 0;
		}

		[hidden],
		template {
			display: none;
		}

		a {
			background-color: transparent;
		}

		a:active,
		a:hover {
			outline: 0;
		}

		abbr[title] {
			border-bottom: 1px dotted;
		}

		b,
		strong {
			font-weight: bold;
		}

		dfn {
			font-style: italic;
		}

		mark {
			background: #ff0;
			color: #000;
		}

		small {
			font-size: 80%;
		}

		sub,
		sup {
			font-size: 75%;
			line-height: 0;
			position: relative;
			vertical-align: baseline;
		}

		sup {
			top: -0.5em;
		}

		sub {
			bottom: -0.25em;
		}

		img {
			border: 0;
		}

		svg:not(:root) {
			overflow: hidden;
		}

		figure {
			margin: 1em 40px;
		}

		hr {
			-moz-box-sizing: content-box;
			box-sizing: content-box;
			height: 0;
		}

		pre {
			overflow: auto;
		}

		code,
		kbd,
		pre,
		samp {
			font-family: monospace, monospace;
			font-size: 1em;
		}

		button,
		input,
		optgroup,
		select,
		textarea {
			color: inherit; /* 1 */
			font: inherit; /* 2 */
			margin: 0; /* 3 */
		}

		button {
			overflow: visible;
		}

		button,
		select {
			text-transform: none;
		}

		button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
			-webkit-appearance: button; /* 2 */
			cursor: pointer; /* 3 */
		}

		button[disabled],
		html input[disabled] {
			cursor: default;
		}

		button::-moz-focus-inner,
		input::-moz-focus-inner {
			border: 0;
			padding: 0;
		}

		input {
			line-height: normal;
		}

		input[type='checkbox'],
		input[type='radio'] {
			box-sizing: border-box; /* 1 */
			padding: 0; /* 2 */
		}

		input[type='number']::-webkit-inner-spin-button,
		input[type='number']::-webkit-outer-spin-button {
			height: auto;
		}

		input[type='search'] {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box; /* 2 */
			box-sizing: border-box;
		}

		input[type='search']::-webkit-search-cancel-button,
		input[type='search']::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:focus {
			transition: background-color 600000s 0s, color 600000s 0s;
		}

		input[data-autocompleted] {
			background-color: transparent !important;
		}

		fieldset {
			border: 1px solid #c0c0c0;
			margin: 0 2px;
			padding: 0.35em 0.625em 0.75em;
		}

		legend {
			border: 0; /* 1 */
			padding: 0; /* 2 */
		}

		textarea {
			overflow: auto;
		}

		optgroup {
			font-weight: bold;
		}

		table {
			border-collapse: collapse;
			border-spacing: 0;
		}

		td,
		th {
			padding: 0;
		}

		// global
		html {
			line-height: ${theme.typography.main.mainLineHeight};
			font-size: ${theme.typography.main.mainFontSize};
			color: ${theme.colors.white};
			height: 100%;
		}

		body {
			margin: 0;
			padding: 0;
			font-family: ${theme.typography.main.mainFont};
			font-size: ${theme.typography.main.mainFontSize};
			font-weight: ${theme.typography.main.mainFontWeight};
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			*,
			*:before,
			*:after {
				box-sizing: border-box;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: 0;
			}

			a {
				color: ${theme.colors.black};
				transition: ${theme.animation.transitionDefault};
				text-decoration: none;
				/*      background-image: linear-gradient(to bottom, #2b2b2b 0%, #2b2b2b 100%); убрали из глобального стиля, чтобы не спотыкаться каждый раз*/
				background-position: 0 93%;
				background-repeat: repeat-x;
				background-size: 1px 1px;

				&:hover,
				&:focus {
					text-decoration: none;
					background-image: none;
				}
			}

			ul,
			li {
				margin: 0;
				padding: 0;
				list-style-type: none;
			}

			.wrapper {
				display: flex;
				flex-wrap: wrap;
				margin: 0 auto;
				position: relative;
				max-width: 1390px;
				width: 100%;
				padding: 0 15px;

				&--start {
					justify-content: flex-start;
					align-items: flex-start;
				}

				&--max {
					max-width: 1600px;
				}

				@media (max-width: 1279px) {
					max-width: 100%;
				}

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					padding: 0 40px;

					&--reclamation {
						padding: 0;
					}
				}

				@media (max-width: ${theme.breakpoints.maxMobileWidth}) {
					padding: 0 20px;
				}
			}

			.wrapper__cabinet {
				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					padding: 0;
					display: block;
				}
			}

			.wrapper__standart {
				max-width: 1390px;
				margin: 0 auto;
				padding: 0 15px;

				&--flex {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
				}

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					padding: 0 40px;
				}

				@media (max-width: ${theme.breakpoints.maxMobileWidth}) {
					padding: 0 20px;
					max-width: 100%;
				}
			}

			.wrapper__news-breadcrumbs {
				margin: 28px auto 32px;
			}

			.flex-wrapper {
				display: flex;
				column-gap: 10px;
			}

			.content-wrapper {
				display: flex;
				flex-direction: column;
				min-height: 100vh;
			}

			p {
				font-size: 14px;
				line-height: ${theme.typography.lineHeight.md};
				font-weight: 400;
			}

			.page__content {
				position: relative;
				z-index: 1;
				margin-left: 270px;
				transition: ${theme.animation.transitionDefault};
				padding-right: 80px;
				padding-bottom: 90px;
				min-height: calc(100vh);

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					padding: 0 0 90px 0;
					max-width: 768px;
					margin: 0 auto;
				}
			}

			.ant-tooltip-inner {
				color: ${theme.colors.black};
			}

			.swiper .swiper-pagination {
				bottom: 15px !important;

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					bottom: 12px !important;
				}

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					bottom: 8px !important;
				}
			}

			.image-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				background-image: none;

				img {
					max-width: 100%;
					height: auto;
				}
			}

			.icons-wrapper {
				margin-bottom: 12px;
				svg {
					margin-right: 24px;
					margin-bottom: 13px;

					&:nth-of-type(22n) {
						margin-right: 0;
					}
				}
			}

			main {
				position: relative;
				background-color: ${theme.colors.white};
				min-height: calc(100vh - 290px);

				@media (max-width: ${theme.breakpoints.maxTableWidth}) {
					position: static;
				}

				@media (max-width: ${theme.breakpoints.maxMobileWidth}) {
					min-height: calc(100vh - 540px);
				}
			}

			.main-wrapper {
				max-width: 1600px;
				margin: 0 auto;
				display: flex;
				justify-content: center;
			}

			.visually-hidden {
				position: absolute;
				clip: rect(0 0 0 0);
				width: 1px;
				height: 1px;
				margin: -1px;
			}

			html {
				scroll-behavior: smooth;
			}

			h1 {
				font-size: 40px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 400;
			}

			h1.min {
				font-size: 22px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 400;
			}

			h2 {
				font-size: 40px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 400;
			}

			h2.min {
				font-size: 24px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 700;
			}

			h3 {
				font-size: 28px;
				line-height: ${theme.typography.lineHeight.md};
				font-weight: 400;
			}

			h4 {
				font-size: 24px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 400;
			}

			h5 {
				font-size: 24px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 400;
			}

			h6 {
				font-size: 22px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 700;
			}

			h6.min {
				font-size: 14px;
				line-height: ${theme.typography.lineHeight.sm};
				font-weight: 700;
			}

			p.type1 {
				font-size: 22px;
				line-height: ${theme.typography.lineHeight.md};
			}

			p.type2 {
				font-size: 14px;
				line-height: ${theme.typography.lineHeight.md};
			}

			p.type3 {
				font-size: 12px;
				line-height: ${theme.typography.lineHeight.md};
			}

			.lk-wrapper {
				flex-basis: 100%;
				display: flex;
				margin-bottom: 80px;
				min-height: calc(100vh - 334px);

				@media (max-width: ${theme.breakpoints.smDesktopWidth}) {
					flex-direction: column;
					margin-bottom: 40px;
				}

				@media (max-width: ${theme.breakpoints.smDesktopWidth}) {
					flex-direction: column;
					margin-bottom: 32px;
				}
			}
			.icons-section {
				margin-bottom: 20px;
			}

			.cart-outer-wrapper {
				width: 100%;
				height: 100%;
			}

			#modalroot {
				position: sticky;
				bottom: 0;
				z-index: 99;
			}
			

			// взяты из scss

			.ant-picker-panel-container {
				border-radius: 10.5px;
				border: 1px solid $line-gray;
			}

			.ant-picker-header {
				border-bottom: none;
			}

			.ant-picker-header-view {
				font-size: 15px;
				margin-top: 7px;
			}

			.ant-picker-content {
				margin-top: -4px;
			}

			.ant-picker-content th {
				font-size: 10px;
				text-transform: uppercase;
				color: #9c9ead;
			}

			.ant-picker-body {
				padding: 8px 9px 0 9px !important;
			}

			.ant-picker-cell {
				padding: 6px 0 !important;
			}

			.ant-picker-footer {
				border-top: none !important;
				padding-bottom: 8px;
			}

			.ant-picker-today-btn {
				width: 248px;
				height: 40px;
				border-radius: 10.5px;
				border: 1px solid $line-gray;
				color: $black;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 auto;
			}

			.ant-select-dropdown {
				padding: 0 !important;
			}

			//

			.ant-picker-dropdown .ant-picker-time-panel {
				display: none !important;
			}

			.ant-picker:hover,
			.ant-picker-focused {
				border: ${theme.unit * 0.125}px solid ${theme.colors.black};
				box-shadow: none;
			}

			.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
				display: none;
			}

			.ant-picker.ant-picker-disabled {
				cursor: default !important;
			}

			.ant-picker-ranges .ant-picker-ok {
				width: 100%;
			}

			.ant-select-dropdown-placement-bottomLeft {
				border-radius: 0 0 ${theme.radius.large} ${theme.radius.large} !important;
			}
		}
		.ant-tooltip-placement-top .ant-tooltip-arrow {
			display: none;
		}
		.ant-picker-panel-container > div > div:nth-child(2) {
			display: none;
		}
		.ant-picker-panel-container
			> div
			> div:nth-child(1)
			> div
			> div.ant-picker-header
			> button.ant-picker-header-next-btn {
			visibility: visible !important;
		}
		.ant-picker-panel-container
			> div
			> div:nth-child(1)
			> div
			> div.ant-picker-header
			> button.ant-picker-header-super-next-btn {
			visibility: visible !important;
		}
	`}

  .popover-overlay-styles {
    padding-top: 0;
    .ant-popover-arrow {
      display: none;
    }
    
  }

  svg use {
	  width: 100%;
	  height: 100%;
  }
`;

export default style.default;
